import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tape-measure',
  templateUrl: './tape-measure.component.html',
  styleUrls: ['./tape-measure.component.scss']
})
export class TapeMeasureComponent implements OnInit {

  fulfillCount:number = 10

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getFulfillCount();
  }

  getFulfillCount() {
    this.http.get('http://www.stoprodobro.cz/php/darujme.php', {responseType: 'json'}).subscribe((val:number) => {
      // console.log(val);
      this.fulfillCount = val;
    })
  }

}
