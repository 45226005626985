import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-top-carousel',
  templateUrl: './top-carousel.component.html',
  styleUrls: ['./top-carousel.component.scss']
})
export class TopCarouselComponent implements OnInit {
    // @Input() offset: number;

    path = 'assets/carousel'
    images = [
      this.path + 1 + '.jpg',
      this.path + 9 + '.jpg',
      this.path + 2 + '.jpg',
      this.path + 3 + '.jpg',
      this.path + 11 + '.jpg',
      this.path + 4 + '.jpg',
      this.path + 5 + '.jpg',
      // this.path + 6 + '.jpg',
      // this.path + 7 + '.jpg',
      this.path + 8 + '.jpg',
      // this.path + 10 + '.jpg',
    ]

    constructor() {
      this.shuffleArray(this.images)
      // for (var i = 0; i < this.offset; i++) {
      //   this.images.push(this.images.shift())
      // }
    }

    ngOnInit() {

    }

    shuffleArray(array:any) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    }
}
