import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faces-bar',
  templateUrl: './faces-bar.component.html',
  styleUrls: ['./faces-bar.component.scss']
})
export class FacesBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
