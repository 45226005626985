import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
  ) {}
  ngOnInit() {
    // this.textFitStaciStoLidi()
  }

  // textFitStaciStoLidi() {
  //   textFit(document.getElementById('stacisto'), {
  //     alignHoriz: true,
  //     // alignVert: true,
  //     reProcess: true,
  //     widthOnly: true,
  //     // multiLine: false,
  //     maxFontSize: 48
  //   });
  // }

}
